import { mapState, mapActions } from 'vuex'
import { saveAs } from 'file-saver'

export default {
  data: () => ({
    items: []
  }),
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'loading',
      'url',
      'total',
      'selected'
    ]),
    checkIfIsMobileDevice () {
      return window.innerWidth <= 768
    }
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setLoading'
    ]),
    async getAccounts () {
      this.items = []
      await this.selected.length > 0 ? this.items = this.selected : await this.getItems()
    },
    async getItems () {
      const url = `${this.url.baseUrl}/${this.total}?${this.url.filters}`

      await this.$http.get(url)
        .then(res => {
          this.items = res.data.data
        })
    },
    async exportAccounts (keys) {
      this.setLoading(true)

      const response = []
      const filename = `contas_${new Date().toLocaleString()}.txt`

      await this.getAccounts()

      // get last index
      const lastIndex = this.items.length - 1
      for (const [index] of this.items.entries()) {
        response.push(this.interpreter(this.variables(index), `${keys}${index === lastIndex ? '' : '\n'}`))
      }

      console.log(response)

      if (this.checkIfIsMobileDevice) {
        await this.copyToClipboard(response.join(''))
        return
      }

      await this.makeFile(filename, response.join(''))
    },
    async makeFile (filename, text) {
      const blob = new Blob([text], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, filename)

      this.setLoading(false)
    },
    async copyToClipboard (text) {
      const el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.setLoading(false)
    },
    variables (index) {
      const item = this.items[index]
      return {
        usuario: item.username,
        senha: item.password,
        email: item.email,
        email_senha: item.email_password
      }
    },
    interpreter (variables, text) {
      let newText = text

      for (let i = 0; i < Object.keys(variables).length; i++) {
        newText = newText.replaceAll(
          `${Object.keys(variables)[i]}`,
          `${Object.values(variables)[i]}`
        )
      }

      return newText
    }
  }
}
