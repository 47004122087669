import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data: () => ({
    showTable: true,
    url: '/user/controle_infinity/accounts/paginate'
  }),
  computed: {
    isMobile () {
      return window.innerWidth < 768
    }
  },
  methods: {
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado com sucesso!',
          icon: 'CheckCircleIcon',
          variant: 'success'
        }
      })
    },
    open (id) {
      this.$router.push({ name: 'Controle Infinity (Gerenciar Contas / Atualizar Conta)', params: { id: id } })
    }
  }
}
