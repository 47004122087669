<template>
  <span v-if="selected.length > 0">
    <b-button
      class="mt-1"
      variant="relief-dark"
      @click.stop="changePasswordModal = true"
    >
      Trocar Senha [{{ selected.length }}]
    </b-button>

    <b-modal
      v-model="changePasswordModal"
      title="Editar senha das contas"
      cancel-variant="outline-secondary"
      centered
    >
      <b-form-group
        label-size="md"
        label-align="left"
        label="Senha"
        label-for="defaultLabel"
      >
        <b-form-input
          id="defaultLabel"
          v-model="password"
          class="w-100"
          placeholder=""
        />
      </b-form-group>

      <template #modal-footer>
        <b-button
          variant="relief-primary"
          :disabled="loading"
          block
          @click.stop="edit()"
        >
          <span v-if="!loading">Salvar</span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import {
  BButton, BModal, BFormGroup, BFormInput, BSpinner
} from 'bootstrap-vue'
import FuncChangePassword from './Mixins/FuncChangePassword'

export default {
  components: {
    BButton, BModal, BFormGroup, BFormInput, BSpinner
  },
  mixins: [FuncChangePassword]
}
</script>
