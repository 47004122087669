import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    items: []
  }),
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'loading',
      'accounts',
      'selected'
    ]),
    checkIfIsMobileDevice () {
      return window.innerWidth <= 768
    }
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setLoading',
      'setReloadingTable'
    ]),
    async setStatus (platform) {
      this.setLoading(true)
      const url = '/user/controle_infinity/accounts/set_platform'
      const params = {
        platform: platform,
        listIds: this.selected.map(item => item.id)
      }

      await this.$http.post(url, params)
        .then(() => {
          this.setReloadingTable(true)
        })
        .finally(() => {
          this.setLoading(false)
        })
    }
  }
}
