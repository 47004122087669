var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.options.length > 0)?_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-prepend',[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-form-select',{staticClass:"w-25",attrs:{"value":_vm.filter.filterBy,"options":_vm.options,"size":"sm"},on:{"change":function (val) { return _vm.setFilter({
          filterBy: val,
          filterText: _vm.filter.filterText,
          filterStatus: _vm.filter.filterStatus,
          filterPlatform: _vm.filter.filterPlatform
        }); }}})],1)],1),_c('b-form-input',{attrs:{"id":"filterInput","value":_vm.filter.filterText,"type":"search","placeholder":"Escreva o que deseja pesquisar."},on:{"change":function (val) { return _vm.setFilter({
      filterBy: _vm.filter.filterBy,
      filterText: val,
      filterStatus: _vm.filter.filterStatus,
      filterPlatform: _vm.filter.filterPlatform
    }); }}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }