<template>
  <span>
    <b-button
      class="mt-1"
      :variant="active ? 'relief-success' : 'relief-primary'"
      @click.stop="openFilterModal()"
    >
      <span>Filtrar</span>&nbsp;
      <feather-icon icon="FilterIcon" />
    </b-button>

    <b-modal
      v-model="visible"
      title="Filtrar contas"
      centered
    >
      <!-- Modal Body -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-align="center"
              label-size="lg"
              label="Situação"
              label-for="filterStatus"
            >
              <b-form-select
                id="filterStatus"
                v-model="filterStatus"
                :state="filterStatus === null ? false : true"
                :options="status_options"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                  >Por favor, selecione uma situação!</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label-align="center"
              label-size="lg"
              label="Plataforma"
              label-for="filterPlatform"
            >
              <b-form-select
                id="filterPlatform"
                v-model="filterPlatform"
                :state="filterPlatform === null ? false : true"
                :options="platform_options"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                  >Por favor, selecione uma plataforma!</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label-align="center"
              label-size="lg"
              label="Data de inicio de bloqueio"
              label-for="filterPlatform"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="Trash2Icon"
                    class="feather-icon cursor-pointer"
                    size="18"
                    @click.stop="startBlockedAt = null"
                  />
                </b-input-group-prepend>
                <DateTimePicker
                  v-if="renderDatetimePicker"
                  v-model="startBlockedAt"
                  :render="renderDatetimePicker"
                  :config="config"
                  locale="pt"
                  alt-timezone="America/Sao_Paulo"
                  date-timezone="UTC"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label-align="center"
              label-size="lg"
              label="Data final de bloqueio"
              label-for="filterPlatform"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon
                    icon="Trash2Icon"
                    class="feather-icon cursor-pointer"
                    size="18"
                    @click.stop="endBlockedAt = null"
                  />
                </b-input-group-prepend>
                <DateTimePicker
                  v-if="renderDatetimePicker"
                  v-model="endBlockedAt"
                  :render="renderDatetimePicker"
                  :config="config"
                  locale="pt"
                  alt-timezone="America/Sao_Paulo"
                  date-timezone="UTC"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </section>

      <!-- Modal Footer -->
      <template #modal-footer="{ hide }">
        <b-button
          size="sm"
          variant="relief-primary"
          @click.stop="hide"
        >
          Fechar
        </b-button>

        <b-button
          size="sm"
          variant="relief-success"
          @click.stop="filterIt()"
        >
          Filtrar
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import {
  BButton,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BInputGroup,
  BInputGroupPrepend
} from 'bootstrap-vue'
import FuncTableFilter from './Mixins/FuncTableFilter'
import DateTimePicker from '@/components/DateTimePicker.vue'

export default {
  components: {
    BButton,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    BInputGroupPrepend,
    DateTimePicker
  },
  mixins: [FuncTableFilter],
  methods: {
    openFilterModal () {
      this.visible = !this.visible
    }
  }
}
</script>
