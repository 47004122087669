<template>
  <b-form-group class="mb-0">
    <label class="d-inline-block text-sm-left mr-50">Por Página</label>
    <b-form-select
      id="perPageSelect"
      :value="perPage"
      size="sm"
      :options="options"
      class="w-25"
      @change="(val) => setPerPage(val)"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    BFormGroup,
    BFormSelect
  },
  data: () => ({
    options: [3, 5, 10, 25, 50, 100, 250, 500, 1000, 2000, 5000]
  }),
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'perPage'
    ])
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setPerPage'
    ])
  }
}
</script>
