<template>
  <span>
    <b-modal
      v-model="visible"
      centered
      no-close-on-backdrop
      hide-footer
      title="Customizar Exportação"
      @change="(val) => $emit('update:visible', val)"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center mb-1"
        >
          Exemplos de exportação
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-center mb-1"
        >
          <b-button
            variant="relief-primary"
            @click.stop="custom = 'usuario,senha'"
          >
            Exemplo 1 - Modo Simples
          </b-button>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-center mb-1"
        >
          <b-button
            variant="relief-primary"
            @click.stop="custom = 'usuario,senha,email,email_senha'"
          >
            Exemplo 2 - Modo Completo
          </b-button>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-center mb-1"
        >
          <b-button
            variant="relief-primary"
            @click.stop="custom = 'usuario\\nsenha\\n'"
          >
            Exemplo 3 - Formato SC.Robot
          </b-button>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-center mb-1"
        >
          <b-button
            variant="relief-primary"
            @click.stop="custom = 'usuario senha'"
          >
            Exemplo 4 - Formato NextGen
          </b-button>
        </b-col>
      </b-row>
      <b-form-group
        label-size="lg"
        label-align="center"
        label="Modelo de exportação"
        label-for="defaultLabel"
      >
        <b-form-input
          id="defaultLabel"
          v-model="custom"
          class="w-100 text-center"
          placeholder="usuario,senha,email,email_senha"
        />
      </b-form-group>

      <!-- <div class="d-flex justify-content-center">
        <h4>Pre-visualização</h4> <br>
        <span v-html="customHtmlPreview" />
      </div> -->

      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <h4>Pre-visualização</h4>
        </b-col>

        <b-col
          cols="12"
        >
          <span v-html="customHtmlPreview" />
        </b-col>
      </b-row>

      <hr>

      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center mb-1"
        >
          <b-button
            variant="relief-danger"
            @click.stop="exportAccounts()"
          >Exportar</b-button>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <b-button
            variant="relief-primary"
            @click.stop="$emit('update:visible', false)"
          >Fechar</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean
    }
  },
  data: () => ({
    custom: ''
  }),
  computed: {
    customHtmlPreview () {
      const custom = `${this.custom}\\n${this.custom}`
      return custom.replace(/\\n/g, '<br>')
    }
  },
  methods: {
    exportAccounts () {
      const custom = this.custom.replace(/\\n/g, '\n')
      this.$emit('update:visible', false)
      this.$emit('custom', custom)
    }
  }
}
</script>
