<template>
  <span v-if="selected.length > 0">
    <b-dropdown
      class="mt-1"
      :text="`Alterar Plataforma [${selected.length}]`"
      variant="relief-dark"
      :no-flip="true"
    >
      <b-dropdown-item @click.stop="setStatus('Siga Social')">
        Siga Social
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Dizu')">
        Dizu
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('GNI')">
        GNI
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Kzom')">
        Kzom
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Outros')">
        Outros
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import FuncChangePlatform from './Mixins/FuncChangePlatform'
import TableExportCustom from './TableExportCustom.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    TableExportCustom
  },
  mixins: [FuncChangePlatform]
}
</script>
