import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    pageOptions: [3, 5, 10, 25, 50, 100, 250, 500],
    timeout: null,
    debounceMilliseconds: 250
  }),
  mounted () {
    this.getPagination()
  },
  watch: {
    currentPage () { this.getPagination() },
    perPage () { this.getPagination() },
    'sort.sortBy' () { this.getPagination() },
    'sort.sortDesc' () { this.getPagination() },
    'filter.filterText' () { this.getPagination() },
    'filter.filterBy' () { if (this.filter.filterText !== '') this.getPagination() },
    'filter.filterStatus' () { this.getPagination() },
    'filter.filterPlatform' () { this.getPagination() },
    'filter.startBlockedAt' () { this.getPagination() },
    'filter.endBlockedAt' () { this.getPagination() },
    selectionMode () {
      this.showTable = false
      this.clearSelected()

      setTimeout(() => {
        this.showTable = true
      }, 100)
    },
    selectAll (val) {
      if (val) {
        this.selectAllRows()
        this.setSelectAll(false)
      }
    },
    reloadingTable (val) {
      if (val) {
        this.getPagination()
        this.setReloadingTable(false)
      }
    }
  },
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'loading',
      'fields',
      'total',
      'accounts',
      'selectionMode',
      'currentPage',
      'perPage',
      'sort',
      'filter',
      'selected',
      'selectAll',
      'reloadingTable'
    ]),
    sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    filterValue () {
      return this.filter.filterBy !== ''
        ? this.filter.filterBy
        : this.sortOptions.length > 0
          ? this.sortOptions[0].value
          : ''
    }
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setLoading',
      'setUrl',
      'setTotal',
      'setPerPage',
      'setAccounts',
      'setCurrentPage',
      'setSort',
      'setFilter',
      'setSelected',
      'setSelectAll',
      'setReloadingTable'
    ]),
    async getPagination () {
      this.setFilter({
        filterBy: this.filterValue,
        filterText: this.filter.filterText,
        filterStatus: this.filter.filterStatus,
        filterPlatform: this.filter.filterPlatform,
        startBlockedAt: this.filter.startBlockedAt,
        endBlockedAt: this.filter.endBlockedAt
      })

      const url = `${this.url}/${this.perPage}?page=${this.currentPage}&sortBy=${this.sort.sortBy}&sortDesc=${this.sort.sortDesc}&filterBy=${this.filter.filterBy}&filter=${this.filter.filterText}&filterStatus=${this.filter.filterStatus}&filterPlatform=${this.filter.filterPlatform}&startBlockedAt=${this.filter.startBlockedAt}&endBlockedAt=${this.filter.endBlockedAt}`
      this.setUrl({
        baseUrl: this.url,
        filters: `sortBy=${this.sort.sortBy}&sortDesc=${this.sort.sortDesc}&filterBy=${this.filter.filterBy}&filter=${this.filter.filterText}&filterStatus=${this.filter.filterStatus}&filterPlatform=${this.filter.filterPlatform}&startBlockedAt=${this.filter.startBlockedAt}&endBlockedAt=${this.filter.endBlockedAt}`
      })

      clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        this.setLoading(true)
        await this.$http.get(url)
          .then(res => {
            this.setAccounts(res.data.data)
            this.setTotal(res.data.total)
          })
          .finally(() => {
            this.setLoading(false)
          })
      }, this.debounceMilliseconds)
    },
    checkIfIsSelected (id) {
      const selected = this.selected.find(item => item.id === id)
      if (selected) {
        return true
      }

      return false
    },
    onRowSelected (items) {
      this.setSelected(items)
    },
    selectAllRows () {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected () {
      this.setSelected([])
      this.$refs.selectableTable.clearSelected()
    },
    sortingChanged (item) {
      this.setLoading(true)
      this.setSort({
        sortBy: item.sortBy,
        sortDesc: item.sortDesc
      })
      this.getPagination()
    }
  }
}
