<template>
  <span v-if="selected.length > 0">
    <b-dropdown
      class="mt-1"
      :text="`Alterar situação [${selected.length}]`"
      variant="relief-dark"
      :no-flip="true"
    >
      <b-dropdown-item @click.stop="setStatus('Ativa')">
        Ativa
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Usada recentemente')">
        Usada recentemente
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Movimentando')">
        Movimentando
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Descansando')">
        Descansando
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Bloqueio de Dias')">
        Bloqueio de Dias
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Bloqueio de Selfie')">
        Bloqueio de Selfie
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Bloqueio Temporário')">
        Bloqueio Temporário
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Verificação SMS')">
        Verificação SMS
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Verificação Email')">
        Verificação Email
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Trocar Senha')">
        Trocar Senha
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Banida')">
        Banida
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Aposentada')">
        Aposentada
      </b-dropdown-item>

      <b-dropdown-item @click.stop="setStatus('Outros')">
        Outros
      </b-dropdown-item>
    </b-dropdown>
  </span>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import FuncChangeStatus from './Mixins/FuncChangeStatus'
import TableExportCustom from './TableExportCustom.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    TableExportCustom
  },
  mixins: [FuncChangeStatus]
}
</script>
