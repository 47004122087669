import { mapState } from 'vuex'

export default {
  data: () => ({
    loading: false,
    changePasswordModal: false,
    password: ''
  }),
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'selected'
    ]),
    accountsIds () {
      return this.selected.map(item => item.id)
    }
  },
  methods: {
    async edit () {
      this.loading = true
      const url = '/user/controle_infinity/accounts/change_password'
      const params = {
        accounts_id: this.accountsIds,
        password: this.password
      }

      await this.$http.post(url, params)
        .finally(() => {
          this.loading = false
          this.changePasswordModal = false
          this.password = ''

          this.$swal({
            title: 'Senhas atualizadas!',
            text: 'Suas senhas foram atualizadas com sucesso!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        })
    }
  }
}
