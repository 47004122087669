<template>
  <b-button
    class="mt-1"
    :variant="fullMode ? 'relief-success' : 'relief-primary'"
    size="md"
    @click.stop="setFullMode()"
  >
    <feather-icon
      :icon="fullMode ? 'MinimizeIcon' : 'MaximizeIcon'"
    />
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import FuncFullModeMixin from './Mixins/FuncFullModeMixin'

export default {
  components: {
    BButton
  },
  mixins: [FuncFullModeMixin]
}
</script>
