<template>
  <b-button
    class="mt-1"
    :variant="tableMode_local ? 'relief-success' : 'relief-warning'"
    @click.stop="updateTableMode()"
  >
    <span v-if="tableMode_local">Modo Completo</span>
    <span v-else>Modo Simples</span>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import FuncTableModeMixin from './Mixins/FuncTableModeMixin'

export default {
  components: {
    BButton
  },
  mixins: [FuncTableModeMixin]
}
</script>
