<template>
  <span>
    <b-dropdown
      class="mt-1"
      :text="selected.length > 0 ? `Exportar [${selected.length}]` : 'Exportar'"
      variant="relief-danger"
    >
      <b-dropdown-item @click.stop="exportAccounts('usuario')">
        Apenas Usuário
      </b-dropdown-item>

      <b-dropdown-item @click.stop="exportAccounts('usuario,senha')">
        Modo Simples
      </b-dropdown-item>

      <b-dropdown-item @click.stop="exportAccounts('usuario,senha,email,email_senha')">
        Modo Completo
      </b-dropdown-item>

      <b-dropdown-item @click.stop="exportAccounts('usuario\nsenha\n')">
        Formato SC.Robot
      </b-dropdown-item>

      <b-dropdown-item @click.stop="exportAccounts('usuario senha')">
        Formato NextGen
      </b-dropdown-item>

      <b-dropdown-item @click.stop="customExportModel = true">
        Definir Formato personalizado
      </b-dropdown-item>
    </b-dropdown>

    <TableExportCustom
      v-model="customExportModel"
      @custom="exportAccounts($event)"
    />
  </span>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import FuncTableExport from './Mixins/FuncTableExport'
import TableExportCustom from './TableExportCustom.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    TableExportCustom
  },
  mixins: [FuncTableExport],
  data: () => ({
    customExportModel: false
  })
}
</script>
