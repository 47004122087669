<template>
  <div>
    <TableSelectionMode class="mr-1" />
    <TableExport class="mr-1" />
    <ChangeStatus class="mr-1" />
    <ChangePlatform class="mr-1" />
    <ChangePassword class="mr-1" />
    <TableDeleteSelected class="mr-1" />
    <TableMode class="mr-1" />
    <TableFilter class="mr-1" />
    <FullMode class="mr-1" />
    <ImportAccounts class="mr-1" />
    <AddAccount />
  </div>
</template>

<script>
import TableSelectionMode from './TableSelectionMode.vue'
import TableExport from './TableExport.vue'
import TableMode from './TableMode.vue'
import FullMode from './FullMode.vue'
import TableDeleteSelected from './TableDeleteSelected.vue'
import ImportAccounts from './ImportAccounts.vue'
import AddAccount from './AddAccount.vue'
import ChangeStatus from './ChangeStatus.vue'
import ChangePlatform from './ChangePlatform.vue'
import ChangePassword from './ChangePassword.vue'
import TableFilter from './TableFilter.vue'

export default {
  components: {
    TableSelectionMode,
    TableExport,
    TableMode,
    FullMode,
    TableDeleteSelected,
    ImportAccounts,
    AddAccount,
    ChangeStatus,
    ChangePlatform,
    ChangePassword,
    TableFilter
  }
}
</script>
