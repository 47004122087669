import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    active: false,
    visible: false,
    selected: 1,
    filterStatus: null,
    filterPlatform: null,
    platform_options: [
      {
        text: 'Siga Social',
        value: 'Siga Social'
      },
      {
        text: 'Dizu',
        value: 'Dizu'
      },
      {
        text: 'GNI',
        value: 'GNI'
      },
      {
        text: 'Kzom',
        value: 'Kzom'
      },
      {
        text: 'Outros',
        value: 'Outros'
      }
    ],
    status_options: [
      {
        value: 'Ativa',
        text: 'Ativa'
      },
      {
        value: 'Usada recentemente',
        text: 'Usada recentemente'
      },
      {
        value: 'Movimentando',
        text: 'Movimentando'
      },
      {
        value: 'Descansando',
        text: 'Descansando'
      },
      {
        value: 'Bloqueio de Dias',
        text: 'Bloqueio de Dias'
      },
      {
        value: 'Bloqueio de Selfie',
        text: 'Bloqueio de Selfie'
      },
      {
        value: 'Bloqueio Temporário',
        text: 'Bloqueio Temporário'
      },
      {
        value: 'Verificação SMS',
        text: 'Verificação SMS'
      },
      {
        value: 'Verificação Email',
        text: 'Verificação Email'
      },
      {
        value: 'Trocar Senha',
        text: 'Trocar Senha'
      },
      {
        value: 'Banida',
        text: 'Banida'
      },
      {
        value: 'Aposentada',
        text: 'Aposentada'
      },
      {
        value: 'Outros',
        text: 'Outros'
      }
    ],
    startBlockedAt: '',
    endBlockedAt: '',
    renderDatetimePicker: true,
    config: {
      enableTime: true,
      altInput: true,
      altFormat: 'd/m/Y H:i',
      dateFormat: 'Y-m-d H:i'
    }
  }),
  watch: {
    startBlockedAt (val) {
      val === null
        ? this.resetDateTimePicker()
        : null
    },
    endBlockedAt (val) {
      val === null
        ? this.resetDateTimePicker()
        : null
    }
  },
  mounted () {
    this.filterStatus = this.filter.filterStatus === '' ? null : this.filter.filterStatus
    this.filterPlatform = this.filter.filterPlatform === '' ? null : this.filter.filterPlatform
    this.startBlockedAt = this.filter.startBlockedAt === '' ? null : this.filter.startBlockedAt
    this.endBlockedAt = this.filter.endBlockedAt === '' ? null : this.filter.endBlockedAt

    this.filterPlatform !== null || this.filterStatus !== null || this.startBlockedAt !== null || this.endBlockedAt !== null
      ? this.active = true
      : this.active = false

    setTimeout(() => {
      this.renderDatetimePicker = true
    }, 1000)
  },
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'filter'
    ])
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setFilter'
    ]),
    resetDateTimePicker () {
      this.renderDatetimePicker = false

      setTimeout(() => {
        this.renderDatetimePicker = true
      }, 1)
    },
    filterIt () {
      this.setFilter({
        filterBy: this.filter.filterValue,
        filterText: this.filter.filterText,
        filterPlatform: this.filterPlatform === null ? '' : this.filterPlatform,
        filterStatus: this.filterStatus === null ? '' : this.filterStatus,
        startBlockedAt: this.startBlockedAt === null ? '' : this.startBlockedAt,
        endBlockedAt: this.endBlockedAt === null ? '' : this.endBlockedAt
      })

      this.filterPlatform !== null || this.filterStatus !== null || this.startBlockedAt !== null || this.endBlockedAt !== null
        ? this.active = true
        : this.active = false

      this.visible = false
    }
  }
}
