import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'selected'
    ])
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setLoading',
      'setReloadingTable'
    ]),
    dialogConfirmDelete () {
      // use $swal
      this.$swal({
        title: 'Você tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, apague!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(result => {
        if (result.value) {
          this.deleteSelected()
        }
      })
    },
    async deleteSelected () {
      this.setLoading(true)
      const url = '/user/controle_infinity/accounts/delete'
      const params = {
        listIds: this.selected.map(item => item.id)
      }

      await this.$http.post(url, params)
        .finally(() => {
          this.setLoading(false)
          this.setReloadingTable(true)

          this.$swal({
            title: 'Apagado!',
            text: 'Suas contas foram apagadas com sucesso!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        })
    }
  }
}
