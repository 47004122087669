<template>
  <span v-if="selected.length > 0">
    <b-button
      class="mt-1"
      variant="relief-danger"
      @click.stop="dialogConfirmDelete()"
    >
      Deletar Selecionados [{{ selected.length }}]
    </b-button>
  </span>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import FuncTableDeleteSelected from './Mixins/FuncTableDeleteSelected'

export default {
  components: {
    BButton
  },
  mixins: [FuncTableDeleteSelected]
}
</script>
