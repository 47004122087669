<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    variant="transparent"
    opacity="1"
    blur="5px"
    rounded="sm"
  >
    <b-card>
      <b-card-title>
        <b-row>
          <b-col
            md="3"
            sm="4"
            class="my-1"
          >
            <PerPage />
          </b-col>

          <b-col
            md="5"
            sm="8"
            class="my-1"
          >
            <Sort :options="sortOptions" />
          </b-col>

          <b-col
            md="4"
            class="my-1"
          >
            <FilterTable :options="sortOptions" />
          </b-col>
        </b-row>
      </b-card-title>

      <b-card-title>
        <Functions />
      </b-card-title>

      <b-card-text>
        <b-table
          v-if="showTable"
          ref="selectableTable"
          striped
          hover
          small
          no-border-collapse
          selectable
          :responsive="isMobile ? 'sm' : ''"
          :sort-by.sync="sort.sortBy"
          :sort-desc.sync="sort.sortDesc"
          :select-mode="selectionMode"
          :per-page="perPage"
          :items="accounts"
          :fields="fields"
          @row-selected="onRowSelected"
          @sort-changed="sortingChanged"
        >
          <template
            v-if="selected.length > 0"
            #cell(checkbox)="data"
          >
            <b-form-checkbox
              :checked="checkIfIsSelected(data.item.id)"
              disabled="true"
            />
          </template>

          <template #cell(status)="data">
            <b-badge :style="`background-color: ${getColor(data.item.status)}`">
              {{ data.item.status }}
            </b-badge>
          </template>

          <template #cell(username)="data">
            <b-button
              v-clipboard:copy="data.item.username"
              v-clipboard:success="onCopy"
              size="sm"
              variant="relief-primary"
            >
              {{ data.item.username }}
            </b-button>
          </template>

          <template #cell(password)="data">
            <b-button
              v-clipboard:copy="data.item.password"
              v-clipboard:success="onCopy"
              size="sm"
              variant="relief-primary"
            >
              {{ data.item.password }}
            </b-button>
          </template>

          <template #cell(email)="data">
            <b-button
              v-clipboard:copy="data.item.email"
              v-clipboard:success="onCopy"
              size="sm"
              variant="relief-primary"
            >
              {{ data.item.email }}
            </b-button>
          </template>

          <template #cell(email_password)="data">
            <b-button
              v-clipboard:copy="data.item.email_password"
              v-clipboard:success="onCopy"
              size="sm"
              variant="relief-primary"
            >
              {{ data.item.email_password }}
            </b-button>
          </template>

          <template #cell(actions)="data">
            <b-button
              class="mr-1"
              size="sm"
              variant="relief-primary"
              @click.stop="open(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </template>
        </b-table>

        <b-row class="mt-2">
          <b-col cols="12">
            <b-pagination
              v-if="!loading"
              :value="currentPage"
              :total-rows="total"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
              @change="(val) => setCurrentPage(val)"
            />
            <b-pagination
              v-if="loading"
              :value="currentPage"
              :total-rows="total"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BTable,
  BPagination,
  BButton,
  BBadge,
  BFormCheckbox
} from 'bootstrap-vue'
import IndexMixin from './IndexMixin'
import TableMixin from './Table/TableMixin'
import PerPage from './Table/PerPage.vue'
import Sort from './Table/Sort.vue'
import FilterTable from './Table/FilterTable.vue'
import Functions from './Functions/Index.vue'
import checkSubscription from '../checkSubscription'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BTable,
    BPagination,
    BButton,
    BBadge,
    BFormCheckbox,
    PerPage,
    Sort,
    FilterTable,
    Functions
  },
  mixins: [IndexMixin, TableMixin, checkSubscription],
  methods: {
    getColor (status) {
      switch (status) {
      case 'Ativa':
        return '#33D67C'
      case 'Usada recentemente':
        return '#194005'
      case 'Movimentando':
        return '#00D7D0'
      case 'Descansando':
        return '#6fa8dc'
      case 'Bloqueio de Dias':
        return '#ff9300'
      case 'Bloqueio de Selfie':
        return '#5b5b5b'
      case 'Bloqueio Temporário':
        return '#FFAC5D'
      case 'Verificação SMS':
        return '#ED6B6C'
      case 'Verificação Email':
        return '#2c0b94'
      case 'Trocar Senha':
        return '#7f5a30'
      case 'Banida':
        return '#660000'
      case 'Aposentada':
        return '#999999'
      case 'Outros':
        return '#674ea7'
      default:
        return 'secondary'
      }
    }
  }
}
</script>
