<template>
  <b-button
    class="mt-1"
    variant="relief-primary"
    @click.stop="$router.push({ name: 'Controle Infinity (Gerenciar Contas / Importar Contas)' })"
  >
    Importar Contas
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton
  }
}
</script>
