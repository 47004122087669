<template>
  <b-dropdown
    class="mt-1"
    text="Selecionar"
    variant="relief-primary"
  >
    <b-dropdown-item
      v-if="selectionMode !== null"
      @click.stop="setSelectionMode(null)"
    >
      Cancelar seleção (ESC)
    </b-dropdown-item>
    <b-dropdown-item @click.stop="setSelectionMode('multi')">
      Seleção com Clique
    </b-dropdown-item>
    <b-dropdown-item
      @click.stop="setSelectionMode('range')"
    >Seleção com Teclado e Mouse</b-dropdown-item>
    <b-dropdown-item @click.stop="selectAll()">
      Selecionar Tudo
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    BDropdown,
    BDropdownItem
  },
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'selectionMode'
    ])
  },
  mounted () {
    window.addEventListener('keyup', e => {
      if (e.key === 'Escape') {
        this.$emit('clear')
        this.setSelectionMode(null)
      }
    })
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setSelectionMode',
      'setSelectAll'
    ]),
    selectAll () {
      this.setSelectionMode('multi')

      setTimeout(() => {
        this.setSelectAll(true)
      }, 500)
    }
  }
}
</script>
