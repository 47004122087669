import { mapActions } from 'vuex'

export default {
  data: () => ({
    fullMode: false
  }),
  methods: {
    ...mapActions('appConfig',
      {
        updateNavMenuHidden: 'UPDATE_NAV_MENU_HIDDEN',
        updateNavbarConfig: 'UPDATE_NAVBAR_CONFIG'
      }),
    setFullMode () {
      this.fullMode = !this.fullMode

      if (this.fullMode) {
        this.updateNavMenuHidden(true)
        this.updateNavbarConfig({
          type: 'hidden',
          backgroundColor: ''
        })
      } else {
        this.updateNavMenuHidden(false)
        this.updateNavbarConfig({
          type: 'floating',
          backgroundColor: ''
        })
      }
    }
  }
}
