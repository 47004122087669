<template>
  <b-form-group
    v-if="options.length > 0"
    label="Ordenar Por"
    label-cols-sm="3"
    label-align-sm="right"
    label-size="sm"
    label-for="sortBySelect"
    class="mb-0"
  >
    <b-input-group size="sm">
      <b-form-select
        id="sortBySelect"
        :value="sort.sortBy"
        :options="options"
        class="w-75"
        @change="val => setSort({
          sortBy: val,
          sortDesc: sort.sortDesc
        })"
      >
        <template v-slot:first>
          <option value="">
            -- Nenhum --
          </option>
        </template>
      </b-form-select>
      <b-form-select
        :value="sort.sortDesc"
        size="sm"
        :disabled="!sort.sortBy"
        class="w-25"
        @change="val => setSort({
          sortBy: sort.sortBy,
          sortDesc: val
        })"
      >
        <option :value="false">
          Asc
        </option>
        <option :value="true">
          Desc
        </option>
      </b-form-select>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BInputGroup, BFormSelect } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormSelect
  },
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'sort'
    ])
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setSort'
    ])
  }
}
</script>
