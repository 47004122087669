<template>
  <b-input-group
    v-if="options.length > 0"
    size="sm"
  >
    <b-input-group-prepend>
      <b-input-group size="sm">
        <b-form-select
          :value="filter.filterBy"
          :options="options"
          size="sm"
          class="w-25"
          @change="(val) => setFilter({
            filterBy: val,
            filterText: filter.filterText,
            filterStatus: filter.filterStatus,
            filterPlatform: filter.filterPlatform
          })"
        />
      </b-input-group>
    </b-input-group-prepend>
    <b-form-input
      id="filterInput"
      :value="filter.filterText"
      type="search"
      placeholder="Escreva o que deseja pesquisar."
      @change="(val) => setFilter({
        filterBy: filter.filterBy,
        filterText: val,
        filterStatus: filter.filterStatus,
        filterPlatform: filter.filterPlatform
      })"
    />
  </b-input-group>
</template>

<script>
import {
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BFormInput
} from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BFormInput
  },
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState('ControleInfinityMyAccounts', [
      'filter'
    ])
  },
  methods: {
    ...mapActions('ControleInfinityMyAccounts', [
      'setFilter'
    ])
  }
}
</script>
